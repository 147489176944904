// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {      
        /*
        primary: '#ee0e0e', 
        secondary: '#f4a082',
        accent: '#ee0e0e',
        gradient: '#f2f6f7',

        "layout-appbar__appbar": "#f2f6f7",
        // "layout-appbar__appbar": "#FFFFFF",
        */
        primary: '#59be85', 
        secondary: '#2c3e50',
        accent: '#3498db',
        gradient: '#2c3e50',

        "layout-appbar__appbar": "#f2f6f7",
      },
      dark: {
        primary: '#59be85', 
        secondary: '#222f3e',
        accent: '#428bca',
        gradient: '#2c3e50',
        
      },
    },
  },
})
